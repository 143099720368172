export const FREQUENCY_TYPE = [
  {
    headerName: "Sr No",
    id: "no",
    isEditable: false,
  },
  {
    headerName: "Frequency Type",
    id: "frequencyType",
  },
  {
    headerName: "Frequency",
    id: "frequency",
  },
  {
    headerName: "Discount(%)",
    id: "discountPercentage",
  },
];

export const FREQUENCY_DATA = [
  {
    no: "1",
    frequencyType: "Weekely",
    frequency: 7,
    description: "7 Times",
  },
  {
    no: "2",
    frequencyType: "Weekely",
    frequency: 7,
    description: "7 Times",
  },
  {
    no: "3",
    frequencyType: "Weekely",
    frequency: 7,
    description: "7 Times",
  },
];

export const ACTION_OPTIONS = [
  {
    label: "Edit",
    key: "edit",
  },
  {
    label: "Delete",
    key: "delete",
  },
];
