import React, { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreVert";
import cx from "classnames";

import Food from "../../../public/Food.svg";
import Days from "../../../public/calendar.svg";
import Info from "../../../public/Info.svg";

import TimePeriod from "../../../public/TimePeriod.svg";

import styles from "./planCard.module.scss";
import KebabMenu from "../kebabMenu/KebabMenu";

const PlanCard = (props) => {
  const { plan, index, handleKebabMenuSelect } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [showKebabMenu, setShowKebabMenu] = useState(false);

  const getStatusClassName = (status) => {
    if (status === "ACTIVE") return styles.active;
    if (status === "PAUSED") return styles.paused;
    if (status === "EXPIRED") return styles.inactive;
  };

  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
    setShowKebabMenu(true);
  };

  const handleClose = () => {
    setShowKebabMenu(false);
  };

  return (
    <div className={styles.main}>
      <div className={styles.row}>{index + 1}</div>
      <div className={styles.container}>
        <div className={styles.parent}>
          <img className={styles.img} src={Food} />
          <div>
            <div className={styles.heading}>Meal type</div>
            <div>{plan?.mealPlanName}</div>
          </div>
        </div>
        <div className={styles.separator}></div>
        <div className={styles.parent}>
          <img className={styles.img} src={Days} />
          <div>
            <div className={styles.heading}>Days</div>
            <div style={{ display: "flex", gap: "0.6rem" }}>
              {plan?.applicableWeekdays?.map((day) => (
                <div>{day}</div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.separator}></div>
        <div className={styles.parent}>
          <img className={styles.img} src={Info} />
          <div>
            <div className={styles.heading}>Meal Type</div>
            <div style={{ display: "flex", gap: "0.6rem" }}>
              {plan?.mealTypeSelected?.map((type) => (
                <div>{type}</div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.separator}></div>
        <div className={styles.parent}>
          <img className={styles.img} src={TimePeriod} />
          <div>
            <div className={styles.heading}>Time Period</div>
            <div className={styles.timePeriod}>
              <div>
                {plan?.startDate[2]}-{plan?.startDate[1]}-{plan?.startDate[0]}
              </div>
              -
              {plan?.endDate && (
                <div>
                  {plan?.endDate?.[2]}-{plan?.endDate?.[1]}-{plan?.endDate?.[0]}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.separator}></div>
        <div className={styles.parent}>
          <div className={cx(styles.status, getStatusClassName(plan?.status))}>
            {plan?.status}
          </div>
          <div style={{ cursor: "pointer" }} onClick={handleClick}>
            <MoreHorizIcon sx={{ paddingTop: "0.6rem", cursor: "pointer" }} />
          </div>

          <KebabMenu
            options={
              plan?.status === "ACTIVE"
                ? [{ label: "Pause", key: "pause" }]
                : [{ label: "Resume", key: "resume" }]
            }
            handleClose={handleClose}
            showKebabMenu={showKebabMenu}
            anchorEl={anchorEl}
            handleKebabMenuSelect={(id) =>
              handleKebabMenuSelect(id, plan?.subscriptionId)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
