import React, { useEffect, useState } from "react";

import styles from "./settings.module.scss";
import InputTable from "../../components/atoms/InputTable/InputTable";
import {
  PACKAGE_TYPE,
  FREQUENCY_TYPE,
  MEAL_DATA,
  FREQUENCY_DATA,
} from "./settings.column";
import MealTypeSetup from "../../components/molecules/MealTypeSetup/MealTypeSetup";
import FrequencyTypeSetup from "../../components/molecules/FrequencySetup/FrequencyTypeSetup";
import { getInitialDetails } from "./settings.helper";
import SelectInput from "../../components/atoms/SelectInput/SelectInput";
import ZoneTypeSetup from "../../components/molecules/ZoneSetup/ZoneSetup";
import ZoneTypeForm from "../../components/atoms/zoneTypeForm/ZoneTypeForm";

const Settings = (props) => {
  const [meals, setMeals] = useState([]);
  const [frequency, setFrequency] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedType, setSelectedType] = useState("mealTypeSetup");

  const init = async () => {
    const { mealType, frequencyType } = await getInitialDetails();
    setMeals(mealType);
    setFrequency(frequencyType);
    setIsLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const handleSelect = (id) => {
    setSelectedType(id);
  };

  return (
    <div className={styles.container}>
      <div className={styles.types}>
        <SelectInput
          handleSelect={handleSelect}
          defaultSelectedId={"mealTypeSetup"}
          options={[
            { id: "mealTypeSetup", label: "Meal Type Setup" },
            { id: "frequencyTypeSetup", label: "Frequency Type Setup" },
            { id: "zonyTypeSetup", label: "Zone Type Setup" },
          ]}
        />
        {selectedType === "mealTypeSetup" && (
          <MealTypeSetup init={init} isLoading={isLoading} meals={meals} />
        )}
        {selectedType === "frequencyTypeSetup" && (
          <FrequencyTypeSetup
            init={init}
            isLoading={isLoading}
            frequency={frequency}
          />
        )}
        {selectedType === "zonyTypeSetup" && (
          <ZoneTypeSetup
            init={init}
            isLoading={isLoading}
            frequency={frequency}
          />
        )}
      </div>
    </div>
  );
};

export default Settings;
