import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function KebabMenu(props) {
  const {
    showKebabMenu,
    anchorEl,
    handleClose,
    containerClassName,
    options,
    handleKebabMenuSelect,
  } = props;

  const handleSelect = (key) => {
    handleClose();

    handleKebabMenuSelect(key);
  };
  return (
    <div>
      <Menu
        anchorEl={anchorEl}
        open={showKebabMenu}
        onClose={handleClose}
        className={containerClassName}
        {...props}
      >
        {options?.map((option) => (
          <MenuItem
            sx={{ fontSize: "1.3rem" }}
            onClick={() => handleSelect(option?.key)}
          >
            {option?.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
