import React from "react";
import App from "./App";
import { ThemeProvider } from "@mui/material";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store/store";

import theme from "./constant/theme";
import './index.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider store={configureStore}>
          <App />
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>

  </React.StrictMode>,
  document.getElementById('root')
);
