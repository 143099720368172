import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Login from "./pages/LoginPage/Login";
import CustomerManagement from "./pages/CustomerManagement/CustomerManagement";
import Foodie from "./pages/Foodie/Foodie";
import DailyReports from "./pages/DailyReports/DailyReports";
import SubscriptionManagement from "./pages/SubsriptionManagement/SubsciptionManagement";
import Home from "./pages/home/Home";
import Profile from "./pages/profile/Profile";
import Orders from "./pages/Orders/Orders";
import Settings from "./pages/Settings/Settings";
import { setDefaultModuleService } from "./service/http";
import Support from "./pages/Support/Support";

function App(props) {
  const isUserLoggedIn = localStorage.getItem("accessToken");
  const { isUserLoggedIn: fromState } = useSelector((state) => state.auth);
  const { paymentRequired } = useSelector((state) => state.auth);
  console.log(paymentRequired, "hey ankit");

  useEffect(() => {
    setDefaultModuleService();
  }, []);

  if (isUserLoggedIn || fromState) {
    return (
      <div style={{ height: "100%", backgroundColor: "#fbf6f4" }}>
        <BrowserRouter>
          <Route path="/" component={Foodie} />
          <Switch>
            <Route exact path="/home" component={Home} />
            <Route
              exact
              path="/customerManagement"
              component={CustomerManagement}
            />
            <Route
              exact
              path="/subscriptionManagement"
              component={SubscriptionManagement}
            />
            <Route exact path="/orderManagement" component={DailyReports} />
            <Route exact path="/account" component={Profile} />
            <Route
              path="/customerManagement/:id"
              component={SubscriptionManagement}
            />
            <Route
              path="/orderManagement/orders/:type/:date"
              component={Orders}
            />
            <Route path="/settings" component={Settings} />
            <Route path="/support" component={Support} />

            <Redirect to="home" />
          </Switch>
        </BrowserRouter>
      </div>
    );
  } else {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <Redirect to="/login" />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
