import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import LoginForm from "../../components/molecules/loginForm/LoginForm";
import Weekdays from "../../components/organisms/weekdays/Weekdays";

import styles from "./login.module.scss";
import { logInUser } from "../../action/auth.action";

const Login = (props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState({ isValid: false, errorCode: "" });

  const handleLogInClick = async (payload) => {
    try {
      await dispatch(logInUser(payload)).unwrap();
    } catch (err) {
      setError({ isValid: true, errorCode: err });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <LoginForm error={error} handleLogInClick={handleLogInClick} />
      </div>
      <div className={styles.rightSide}>
        <Weekdays />
      </div>
    </div>
  );
};
export default Login;
