import Http from "../service/http";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const logInUser = createAsyncThunk(
  "auth/logIn",
  async (payload, { rejectWithValue }) => {
    try {
      const apiRes = await Http.post("/p/user/login", payload);
      const response = apiRes?.data;
      const workSpaceResponse = await Http.get("u/workspace/details", [], {
        token: response?.accessToken,
        workspaceId: response?.tenantId,
      });
      const workspaceData = workSpaceResponse?.data;
      localStorage.setItem("workspaceName", workspaceData?.workspaceName);
      localStorage.setItem("workspaceLogo", workspaceData?.logoUrl);
      localStorage.setItem("accessToken", response?.accessToken);
      localStorage.setItem("validUntil", response?.validUntil);
      localStorage.setItem("tenantId", response?.tenantId);
    } catch (err) {
      console.log(err, "himanshu");
      return rejectWithValue(err?.response?.data?.errorCode);
    }
  }
);

export const logOutUser = createAsyncThunk("auth/logIn", async () => {
  const token = localStorage.getItem("accessToken");
  const workspaceId = localStorage.getItem("tenantId");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("validUntil");
  try {
    await Http.put("/u/user/logout", [], "", { token, workspaceId });
  } catch (err) {
    console.log(err);
  }
});
