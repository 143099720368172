import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "2rem",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: 2,
};

export default function BasicModal(props) {
  const {
    isModalOpen,
    handleModalClose,
    ChildComponent,
    heading,
    headingClassName,
  } = props;

  return (
    <div style={{ width: "fitContent" }}>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "0.6rem",
              marginBottom: "2rem",
            }}
          >
            <div style={{ fontSize: "2.4rem" }} className={headingClassName}>
              {heading}
            </div>
            <CloseIcon
              fontSize="large"
              onClick={handleModalClose}
              sx={{
                fontSize: "2rem",
                position: "absolute",
                right: "1.8rem",
                cursor: "pointer",
              }}
            />
          </div>

          <ChildComponent handleModalClose={handleModalClose} />
        </Box>
      </Modal>
    </div>
  );
}
