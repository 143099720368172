import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Http from "../../../service/http";

export const getParedData = (results) => {
  const y = results?.map((result) => ({
    date: `${result?.date[2]}-${result?.date[1]}-${result?.date[0]}`,
    weekday: result.weekday,
    lunch: result?.meals?.includes("LUNCH") ? <CheckIcon /> : <CloseIcon />,
    dinner: result?.meals?.includes("DINNER") ? <CheckIcon /> : <CloseIcon />,
  }));
  return y;
};

export const getPreview = async (payload) => {
  try {
    const token = localStorage.getItem("accessToken");
    const workspaceId = localStorage.getItem("tenantId");
    const response = await Http.post(
      "u/mealSubscription/schedule-preview",
      payload,
      "",
      { token, workspaceId }
    );

    return response?.data;
  } catch (err) {}
};
