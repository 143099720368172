import React from "react";

import Monday from "../../../public/monday.png"
import Tuesday from '../../../public/tuesday.png'
import Wednesday from '../../../public/wednesday.png'
import Thursday from '../../../public/thursday.png'
import Friday from '../../../public/friday.png'
import Saturday from '../../../public/saturday.png'
import styles from './weekday.module.scss';

const Weekdays = (props) => {
  return <div className={styles.container}>
    <div className={styles.item}>
      <img src={Monday} />
      <img src={Tuesday} />
      <img src={Wednesday} />
    </div>
   
      <div className={styles.content}>
        Savor the taste of home,
        <div>Away from home</div>
      </div>
      <div className={styles.item}>
        <img src={Thursday} />
        <img src={Friday} />
        <img src={Saturday} />
      </div>

  </div>
}

export default Weekdays