import React, { useEffect, useState } from "react";
import styles from "./customermanagement.module.scss";
import TableManager from "../../components/atoms/table/TableManager";
import { COLUMNS } from "./customerManagement.column";
import Modal from "../../components/atoms/Modal/Modal";
import Button from "../../components/atoms/button/Button";
import CustomerForm from "../../components/atoms/CustomerForm/CustomerForm";
import AddIcon from "@mui/icons-material/Add";
import {
  getCustomer,
  addCustomer,
  editCustomer,
} from "./customermanagement.helper";
import TextFieldRenderer from "../../components/atoms/textFieldRenderer/TextFieldRenderer";
import { FormControl, InputAdornment, FormControlLabel } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import KebabMenu from "../../components/atoms/kebabMenu/KebabMenu";
import { ACTION_OPTIONS } from "./customerManagement.column";
import EditCustomer from "../../components/atoms/EditCustomer/EditCustomer";

const CustomerManagement = (props) => {
  const { history } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageInfo, setPageInfo] = useState({ totalPage: 0, resultSize: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [showKebabMenu, setShowKebabMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [preValues, setPrevalues] = useState(null);
  const [customerId, setCustomerId] = useState(null);

  useEffect(async () => {
    const { customer, totalPage, resultSize } = await getCustomer(
      searchTerm,
      currentPage - 1
    );
    setPageInfo({ totalPage, resultSize });
    setCustomers(customer);
    setIsLoading(false);
  }, [searchTerm, currentPage]);

  const handlePageChange = (ev, currentPage) => {
    setCurrentPage(currentPage);
  };

  const handleRowClick = (rows) => {
    history.push(`customerManagement/${rows?.customerId}`);
  };

  const handleAddCustomer = async (input) => {
    try {
      await addCustomer(input);
      setIsLoading(false);
      const response = await getCustomer();
      setCustomers(response?.customer);
      setPageInfo({
        totalPage: response.totalPage,
        resultSize: response.resultSize,
      });
      setIsLoading(false);
      setIsModalOpen(false);
    } catch (err) {
      alert(err);
      console.log(err);
    }
  };
  const handlEditCustomer = async (input) => {
    try {
      await editCustomer(input, customerId);
      setIsLoading(false);
      const response = await getCustomer();
      setCustomers(response?.customer);
      setPageInfo({
        totalPage: response.totalPage,
        resultSize: response.resultSize,
      });
      setIsLoading(false);
      setIsEditModalOpen(false);
    } catch (err) {
      alert(err);
      console.log(err);
    }
  };

  const handleSearch = (ev) => {
    if (ev?.keyCode === 13) {
      setSearchTerm(ev?.target?.value);
      setCurrentPage(1);
    }
  };

  const rightSection = () => {
    return (
      <div className={styles.rightSectionContainer}>
        <Button
          className={styles.addCustomer}
          onClick={() => setIsModalOpen(true)}
        >
          {" "}
          <AddIcon /> <div>Add Customer</div>
        </Button>
        <div className={styles.searchBar}>
          <TextFieldRenderer
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "1rem",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={"Search Customer"}
            id="outlined-basic"
            variant="outlined"
            onKeyDown={handleSearch}
          ></TextFieldRenderer>
        </div>
      </div>
    );
  };

  const handleActionClick = (event, rows) => {
    setPrevalues(rows);
    setCustomerId(rows?.customerId);
    event.stopPropagation();
    setAnchorEl(event?.currentTarget);
    setShowKebabMenu(true);
  };

  const handleKebabMenuSelect = (id) => {
    setIsEditModalOpen(true);
  };

  return (
    <>
      <div className={styles.container}>
        <TableManager
          handlePageChange={handlePageChange}
          totalPage={pageInfo.totalPage}
          resultSize={pageInfo.resultSize}
          currentPage={currentPage}
          isLoading={isLoading}
          handleRowClick={handleRowClick}
          rightSection={rightSection}
          headerName="Customer Management"
          data={customers}
          columns={COLUMNS}
          showAction={true}
          handleActionClick={handleActionClick}
        />
        <KebabMenu
          id="kebabMenu"
          handleKebabMenuSelect={(id) => handleKebabMenuSelect(id)}
          options={ACTION_OPTIONS}
          handleClose={() => setShowKebabMenu(false)}
          showKebabMenu={showKebabMenu}
          anchorEl={anchorEl}
        />
        <Modal
          headingClassName={styles.headingClassName}
          heading={"Add Customer"}
          ChildComponent={() => (
            <CustomerForm
              handleModalClose={() => setIsModalOpen(false)}
              handleAddCustomer={handleAddCustomer}
              preValues={preValues}
            />
          )}
          handleModalClose={() => setIsModalOpen(false)}
          isModalOpen={isModalOpen}
        />
        <Modal
          headingClassName={styles.headingClassName}
          heading={"Edit Customer"}
          ChildComponent={() => (
            <EditCustomer
              handleModalClose={() => setIsEditModalOpen(false)}
              handleAddCustomer={handlEditCustomer}
              preValues={preValues}
              customerId={customerId}
            />
          )}
          handleModalClose={() => setIsEditModalOpen(false)}
          isModalOpen={isEditModalOpen}
        />
      </div>
    </>
  );
};

export default CustomerManagement;
