import Http from "../../service/http";

export const getId = (url) => {
  const arr = url?.split("/");
  return arr[arr.length - 1];
};

export const getInitialDetails = async (id) => {
  try {
    const customerDetails = await Http.get(`/u/customer/${id}`);
    const plans = await Http.get(
      `/u/mealSubscription/customer?customerId=${id}`
    );
    const mealPlan = await Http.get("/u/mealPlan/workspace");
    const mealFrequency = await Http.get("/u/mealFrequency/workspace");

    return {
      customerDetails: customerDetails?.data,
      allPlans: plans?.data,
      mealTypePlans: mealPlan?.data,
      mealFrequency: mealFrequency?.data,
    };
  } catch (err) {
    console.log(err);
  }
};

export const AddSubscriptionDetails = async (payload) => {
  try {
    await Http.post("/u/mealSubscription", payload);
  } catch (err) {
    alert(err);
    console.log(err);
  }
};

export const setSubscription = async (id, type, payload = {}) => {
  try {
    if (type === "resume") {
      await Http.put(`/u/mealSubscription/${id}/${type}`, payload);
    } else {
      await Http.put(`/u/mealSubscription/${id}/${type}`);
    }
  } catch (err) {
    console.log(err);
  }
};
