import React from "react";
import { TextField } from "@mui/material";
import styles from "./textField.module.scss";

const TextFieldRenderer = (props) => {
  const { labelText } = props || {};
  return (
    <div className={styles.container}>
      {{ labelText } && <div className={styles.label}>{labelText}</div>}
      <TextField type="email" {...props} />
    </div>
  );
};

export default TextFieldRenderer;
