import axios from "axios";

export default (baseUrl,headers={},options)=>{
    const axiosInstance=axios.create({
        baseURL:baseUrl,
        headers:{
          
            ...headers
        },
        ...options
    })
    return axiosInstance
}