import Http from "../../../service/http";

export const getParsedData = (meals) => {
  return meals?.map((meal, i) => ({
    ...meal,
    no: i + 1,
    packageType: meal?.name,
    basePrice: meal?.unitPrice,
    description: meal?.description,
  }));
};

export const addMealType = async (payload) => {
  try {
    const token = localStorage.getItem("accessToken");
    const workspaceId = localStorage.getItem("tenantId");
    await Http.post("/u/mealPlan", payload, "", { token, workspaceId });
  } catch (err) {
    console.log(err);
  }
};

export const editMealType = async (payload, id) => {
  try {
    const token = localStorage.getItem("accessToken");
    const workspaceId = localStorage.getItem("tenantId");
    await Http.put(`/u/mealPlan/${id}`, payload, "", { token, workspaceId });
  } catch (err) {
    console.log(err);
  }
};
