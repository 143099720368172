import React, { useEffect, useState } from "react";
import TableManager from "../../atoms/table/TableManager";
import { Zone_TYPE_COLUMN, ACTION_OPTIONS } from "./zoneTypeColumn";
import Button from "../../atoms/button/Button";
import AddIcon from "@mui/icons-material/Add";
import styles from "./zoneTypeSetup.module.scss";
import {
  addZoneType,
  editZoneType,
  getAllZoneType,
  getParsedData,
} from "./zoneType.helper";
import BasicModal from "../../atoms/Modal/Modal";
import ZoneTypeForm from "../../atoms/zoneTypeForm/ZoneTypeForm";
import KebabMenu from "../../atoms/kebabMenu/KebabMenu";

const ZoneTypeSetup = (props) => {
  const { isLoading, init } = props;
  const [showModal, setShowModal] = useState(false);
  const [zones, setZones] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showKebabMenu, setShowKebabMenu] = useState(false);
  const [zoneTypes, setZoneTypes] = useState(null);

  const initialFetch = async () => {
    const data = await getAllZoneType();
    setZones(data);
  };

  useEffect(() => {
    initialFetch();
  }, []);

  const handleOnSubmit = async (input) => {
    await addZoneType(input);
    setShowModal(false);
    init();
    initialFetch();
  };

  const handleActionClick = (event) => {
    setAnchorEl(event?.currentTarget);
    setShowKebabMenu(true);
  };

  const rightSection = () => {
    return (
      <Button className={styles.addCustomer} onClick={() => setShowModal(true)}>
        <AddIcon /> <div>Add Zone type</div>
      </Button>
    );
  };

  const handleClose = () => {
    setShowKebabMenu(false);
  };

  const handleKebabMenuSelect = (id) => {
    if (id === "edit") {
      setShowEditModal(true);
    }
  };

  const handleRowClick = (row) => {
    setZoneTypes({ ...row });
  };

  const handleOnEditSubmit = async (input) => {
    await editZoneType(input, zoneTypes?.zoneId);
    setShowEditModal(false);
    init();
    initialFetch();
  };

  return (
    <div>
      <TableManager
        isLoading={isLoading}
        rightSection={rightSection}
        handleRowClick={handleRowClick}
        handleActionClick={handleActionClick}
        data={zones}
        headerName={"Zone Type Setup"}
        columns={Zone_TYPE_COLUMN}
        showAction={true}
        showPagination={false}
      />
      <KebabMenu
        handleKebabMenuSelect={(id) => handleKebabMenuSelect(id)}
        options={ACTION_OPTIONS}
        handleClose={handleClose}
        anchorEl={anchorEl}
        showKebabMenu={showKebabMenu}
      />
      <BasicModal
        headingClassName={styles.headingClassName}
        heading={"Add Zone Type"}
        handleModalClose={() => setShowModal(false)}
        ChildComponent={() => (
          <ZoneTypeForm
            handleOnCancel={() => setShowModal(false)}
            handleOnSubmit={handleOnSubmit}
          />
        )}
        isModalOpen={showModal}
      />
      <BasicModal
        headingClassName={styles.headingClassName}
        heading={"Edit Zone Type"}
        handleModalClose={() => setShowEditModal(false)}
        ChildComponent={() => (
          <ZoneTypeForm
            handleOnCancel={() => setShowEditModal(false)}
            handleOnSubmit={handleOnEditSubmit}
            zoneTypes={zoneTypes}
          />
        )}
        isModalOpen={showEditModal}
      />
    </div>
  );
};

export default ZoneTypeSetup;
