import React, { useState } from "react";
import { InputAdornment } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import SearchIcon from "@mui/icons-material/Search";
import TextFieldRenderer from "../../atoms/textFieldRenderer/TextFieldRenderer";
import styles from "./header.module.scss";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KebabMenu from "../../atoms/kebabMenu/KebabMenu";
import { logOutUser } from "../../../action/auth.action";
import MenuIcon from "@mui/icons-material/Menu";

const Header = (props) => {
  const { history, userData, workspaceDetails, handleMenuClick } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [showKebabMenu, setShowKebabMenu] = useState(false);
  const dispatch = useDispatch();

  const KEBAB_MENU_OPTIONS = [
    {
      label: "Account",
      key: "account",
    },
    {
      label: "Logout",
      key: "logout",
    },
  ];

  const handleKebabMenuSelect = (id) => {
    if (id === "account") {
      history.push("/account");
    }
    if (id === "logout") {
      dispatch(logOutUser());
      window.location.replace("./login");
    }
  };

  const handleProfileClick = (event) => {
    setAnchorEl(event?.currentTarget);
    setShowKebabMenu(true);
  };
  const handleKebabMenuClose = () => {
    setAnchorEl(null);
    setShowKebabMenu(false);
  };

  return (
    <>
      <div className={styles.container}>
        <div onClick={handleMenuClick} className={styles.menu}>
          <MenuIcon sx={{ fontSize: "2rem" }} />
          <div>Menu</div>
        </div>
        <div
          className={styles.workspaceDetails}
          style={{ display: "flex", gap: "1rem" }}
        >
          <img
            src={workspaceDetails?.workspaceLogo}
            className={styles.businessLogo}
          />
          <div className={styles.businessName}>
            {workspaceDetails?.workspaceName}
          </div>
        </div>

        <div className={styles.leftSide}>
          <div onClick={handleProfileClick} className={styles.profile}>
            <AccountCircleIcon fontSize="large" />
            {userData?.firstName}
          </div>
          <KebabMenu
            options={KEBAB_MENU_OPTIONS}
            handleKebabMenuSelect={handleKebabMenuSelect}
            MenuListProps={{
              "aria-labelledby": "basic-button",
              sx: { width: anchorEl && anchorEl.offsetWidth },
            }}
            containerClassName={styles.kebabMenuContainer}
            handleClose={handleKebabMenuClose}
            anchorEl={anchorEl}
            showKebabMenu={showKebabMenu}
          />
        </div>
      </div>
    </>
  );
};

export default Header;
