import Http from "../../../service/http";

export const getParsedData = (zones) => {
  return zones?.map((zone, i) => ({
    ...zone,
    no: i + 1,
    packageType: zone?.name,
    description: zone?.description,
  }));
};

export const addZoneType = async (payload) => {
  try {
    const token = localStorage.getItem("accessToken");
    const workspaceId = localStorage.getItem("tenantId");
    await Http.post("/u/deliveryZone", payload, "", { token, workspaceId });
  } catch (err) {
    console.log(err);
  }
};

export const editZoneType = async (payload, id) => {
  try {
    const token = localStorage.getItem("accessToken");
    const workspaceId = localStorage.getItem("tenantId");
    await Http.put(`/u/deliveryZone/${id}`, payload, "", {
      token,
      workspaceId,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getAllZoneType = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    const workspaceId = localStorage.getItem("tenantId");
    const data = await Http.get(`u/deliveryZone/workspace`, "", {
      token,
      workspaceId,
    });
    return getParsedData(data?.data);
  } catch (err) {
    console.log(err);
  }
};
