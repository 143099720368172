import Http from "../../service/http";

export const MENU_OPTIONS = [
  { label: "Customer Management", value: "customerManagement" },
  { label: "Subscription Management", value: "subscriptionManagement" },
  { label: "Daily Reports", value: "dailyReports" },
];

export const parsedCustomers = (customers) => {
  return customers?.map((customer) => ({
    name: customer?.firstName + " " + customer?.lastName,
    email: customer?.email,
    phoneNumber: customer?.phoneNumber,
    address:
      customer?.address?.line1 +
      customer?.address?.line2 +
      "," +
      customer?.address?.city,
    customerId: customer?.customerId,
  }));
};

export const getCustomer = async (searchTerm, page = 0, size = 20) => {
  try {
    const token = localStorage.getItem("accessToken");
    const workspaceId = localStorage.getItem("tenantId");
    const response = await Http.post(
      "/u/customer/search",
      { page, size, searchTerm },
      "",
      {
        token,
        workspaceId,
      }
    );
    const { data, totalPage, resultSize } = response?.data || {};
    const customer = parsedCustomers(data);
    return { customer, totalPage, resultSize };
  } catch (err) {
    console.log(err);
  }
};

export const addCustomer = async (payload) => {
  try {
    const token = localStorage.getItem("accessToken");
    const workspaceId = localStorage.getItem("tenantId");
    const dto = {
      firstName: payload?.firstName,
      lastName: payload?.lastName,
      email: payload?.email,
      phoneNumber: payload?.phoneNumber,
      deliveryZoneId: payload?.deliveryZoneId,
      address: {
        line1: payload?.addressLine1,
        line2: payload?.addressLine2,
        city: payload?.city,
        state: payload?.state,
        country: payload?.country,
        zipcode: payload?.zipcode,
      },
    };
    await Http.post("/u/customer", dto, "", { token, workspaceId });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const editCustomer = async (payload, customerId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const workspaceId = localStorage.getItem("tenantId");
    const dto = {
      firstName: payload?.firstName,
      lastName: payload?.lastName,
      email: payload?.email,
      phoneNumber: payload?.phoneNumber,
      deliveryZoneId: payload?.deliveryZoneId,
      address: {
        line1: payload?.addressLine1,
        line2: payload?.addressLine2,
        city: payload?.city,
        state: payload?.state,
        country: payload?.country,
        zipcode: payload?.zipcode,
      },
    };
    await Http.put(`/u/customer/${customerId}`, dto, "", {
      token,
      workspaceId,
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
};
