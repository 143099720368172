import { createSlice } from "@reduxjs/toolkit";
import { logInUser } from "../action/auth.action";

const accessToken = localStorage.getItem("accessToken")
  ? localStorage.getItem("accessToken")
  : null;
const validUntil = localStorage.getItem("validUntil")
  ? localStorage.getItem("validUntil")
  : null;

const initialState = {
  loading: false,
  validUntil,
  accessToken,
  error: null,
  success: false,
  isUserLoggedIn: false,
  paymentRequired: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logInUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logInUser.fulfilled, (state, { payload }) => {
        (state.loading = false),
          (state.success = true),
          (state.isUserLoggedIn = accessToken ? true : false);
      })
      .addCase(logInUser.rejected, (state, { payload }) => {
        (state.loading = false),
          (state.isUserLoggedIn = accessToken ? true : false);
        state.paymentRequired = true;
      });
  },
});

export default authSlice.reducer;
