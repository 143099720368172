import Http from "../../service/http";

export const getInitialDetails = async () => {
  try {
    const mealType = await Http.get("/u/mealPlan/workspace", []);
    const frequencyType = await Http.get("/u/mealFrequency/workspace");
    return { mealType: mealType?.data, frequencyType: frequencyType?.data };
  } catch (err) {
    console.log(err);
  }
};
