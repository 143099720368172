export const COLUMNS = [
  {
    headerName: "Name",
    id: "name",
    accessor: "name",
  },
  {
    headerName: "Email",
    id: "email",
    accessor: "email",
  },
  {
    headerName: "Phone",
    id: "phoneNumber",
    accessor: "phoneNumber",
  },
  {
    headerName: "Address",
    id: "address",
    accessor: "address",
  },
];

export const ACTION_OPTIONS = [
  {
    label: "edit",
    value: "edit",
  },
];
