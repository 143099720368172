import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

function SingleSelect(props) {
  const { options, handleChange, value, label } = props;

  const handleValueChange = (event) => {
    handleChange(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="single-select-label">{label}</InputLabel>
      <Select
        {...props}
        value={value}
        onChange={handleValueChange}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200, // Set max height of dropdown
              fontSize: "1.2rem", // Adjust font size
            },
          },
        }}
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SingleSelect;
