import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import TableManager from "../../components/atoms/table/TableManager";
import { COLUMNS } from "./orders.columns";
import styles from "./orders.module.scss";
import KebabMenu from "../../components/atoms/kebabMenu/KebabMenu";
import { ACTION_OPTIONS } from "./orders.columns";
import { getOrderDetails } from "./orders.helper";
import { skipOrder } from "./orders.helper";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Button from "../../components/atoms/button/Button";
import Modal from "../../components/atoms/Modal/Modal";
import ExcelModal from "../../components/atoms/ExcelModal/ExcelModal";

const Orders = (props) => {
  const { o } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [showKebabMenu, setShowKebabMenu] = useState(false);
  const { type, date } = useParams();
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showExcelModal, setShowExcelModal] = useState(false);

  const [orderDetails, setOrderDetails] = useState({
    subscriptionId: null,
    orderId: null,
  });

  const init = async () => {
    const data = await getOrderDetails(type, date);
    setOrders(data);
    setIsLoading(false);
  };
  useEffect(async () => {
    init();
  }, []);

  const handleActionClick = (event) => {
    setAnchorEl(event?.currentTarget);
    setShowKebabMenu(true);
  };

  const handleClose = () => {
    setShowKebabMenu(false);
  };

  const handleSkipMeal = async () => {
    await skipOrder(orderDetails.subscriptionId, orderDetails.orderId);
    init();
  };

  const handleRowClick = (row) => {
    setOrderDetails({
      subscriptionId: row?.subscriptionId,
      orderId: row?.orderId,
    });
  };

  const rightSection = () => {
    return (
      <Button
        className={styles.addCustomer}
        onClick={() => setShowExcelModal(true)}
      >
        {" "}
        <FileDownloadIcon sx={{ fontSize: "1.8rem" }} />
      </Button>
    );
  };

  return (
    <div className={styles.container}>
      <TableManager
        rightSection={rightSection}
        isLoading={isLoading}
        handleRowClick={(row) => handleRowClick(row)}
        handleActionClick={handleActionClick}
        showAction={true}
        headerName="Orders"
        data={orders}
        columns={COLUMNS}
      />
      <KebabMenu
        handleKebabMenuSelect={(id) => handleSkipMeal(id)}
        options={ACTION_OPTIONS}
        handleClose={handleClose}
        anchorEl={anchorEl}
        showKebabMenu={showKebabMenu}
      />
      <Modal
        headingClassName={styles.headingClassName}
        heading={"Select Delivery Zone"}
        ChildComponent={() => (
          <ExcelModal
            handleModalClose={() => setShowExcelModal(false)}
            handleSubmit={() => setShowExcelModal(false)}
          />
        )}
        handleModalClose={() => setShowExcelModal(false)}
        isModalOpen={showExcelModal}
        handleSubmit={() => setShowExcelModal(false)}
      />
    </div>
  );
};

export default Orders;
