import React from "react";
import LOGO from "../../public/Logo.png";
import styles from "./home.module.scss";

const Home = () => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div>
          Streamline your meal planning effortlessly with{" "}
          <span style={{ textDecoration: "underline", color: "#bf2f2a" }}>
            Mealios
          </span>
        </div>
        <div>Your Ultimate Tiffin Management Solution!</div>
      </div>

      <img className={styles.logo} src={LOGO} />
    </div>
  );
};

export default Home;
