import cx from "classnames";
import MuiButton from "@mui/material/Button";

import { withStyles } from "@mui/styles";
import { getStyle } from "./button.styles";

const Button = (props) => {
  const { onclick, type, classes, ...btnProps } = props;
  return (
    <MuiButton className={cx()} onclick={onclick} {...btnProps} {...props} />
  );
};

export default withStyles(getStyle())(Button);
