import React, { useState } from "react";
import { FormControl, InputAdornment } from "@mui/material";
import TextFieldRenderer from "../textFieldRenderer/TextFieldRenderer";
import styles from "./passwordForm.module.scss";
import Button from "../button/Button";

const PasswordForm = (props) => {
  const { handleOnCancel, handleOnSubmit, userDetails } = props;

  const [input, setInput] = useState({
    email: userDetails?.email,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleOnChange = (event) => {
    const { name, value } = event?.target || {};
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    checkSubmitDisabled();
  };

  const checkValidation = () => {
    if (
      input?.confirmPassword !== null &&
      input?.newPassword !== input?.confirmPassword
    )
      return true;
    return false;
  };

  const checkSubmitDisabled = () => {
    if (
      input?.currentPassword === "" ||
      input?.newPassword ||
      input.confirmPassword === ""
    )
      return true;
    return false;
  };

  return (
    <div className={styles.parent}>
      <div className={styles.container}>
        <FormControl sx={{ width: "100%" }}>
          <div className={styles.firstPart}>
            <TextFieldRenderer
              onChange={handleOnChange}
              placeholder="Email"
              name="email"
              type="email"
              defaultValue={userDetails?.email}
              disabled
              labelText="Email"
            />
          </div>
          <div className={styles.firstPart}>
            <TextFieldRenderer
              onChange={handleOnChange}
              placeholder="Current Password"
              name="currentPassword"
              type="password"
              labelText="Current Password"
            />
          </div>
          <div className={styles.firstPart}>
            <TextFieldRenderer
              onChange={handleOnChange}
              placeholder="New Password"
              name="newPassword"
              type="password"
              labelText="New Password"
            />
          </div>
          <div className={styles.firstPart}>
            <TextFieldRenderer
              onChange={handleOnChange}
              name="confirmPassword"
              placeholder="Confirm Password"
              type="password"
              labelText="Confirm Password"
            />
          </div>
          {checkValidation() && (
            <div className={styles.confirmPassword}>
              Confirm password doesn't match New password{" "}
            </div>
          )}
        </FormControl>
      </div>
      <div className={styles.footer}>
        <Button onClick={handleOnCancel} color="tertairy">
          Cancel
        </Button>
        <Button
          disabled={checkValidation()}
          onClick={() => handleOnSubmit(input)}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default PasswordForm;
