import React from "react";
import Food from '../../../public/Food.svg'
import styles from './reportCard.module.scss';
import { getTotalCount } from "./reportCard.helper";

const ReportCard = (props) => {
    const { handleOrderClick, reports } = props;
    const { lunchCount, dinnerCount, dinnerDetails, lunchDetails } = getTotalCount(reports) || {};

    return (<div className={styles.container}>
        <div className={styles.parent}>
            <div onClick={() => handleOrderClick("LUNCH")} className={styles.header}>
                <div style={{ display: 'flex' }}> <img style={{ width: '3rem' }} src={Food} /><div style={{ paddingTop: '1rem', fontSize: '1.6rem', paddingLeft: '0.8rem', }}>Lunch</div></div>
                <div className={styles.value}>{lunchCount}</div>
            </div>
            {lunchDetails?.map((lunch, i) => <div className={styles.meal}>
                <div>{lunch?.mealPlanName}</div>
                <div>{lunch?.totalMealCount}</div>
            </div>)
            }
        </div>
        <div className={styles.parent}>
            <div onClick={() => handleOrderClick("DINNER")} className={styles.header}>
                <div style={{ display: 'flex' }}> <img style={{ width: '3rem' }} src={Food} /><div style={{ paddingTop: '1rem', fontSize: '1.6rem', paddingLeft: '0.8rem' }}>Dinner</div></div>
                <div className={styles.value}>{dinnerCount}</div>
            </div>
            {dinnerDetails?.map((lunch, i) => <div className={styles.meal}>
                <div>{lunch?.mealPlanName}</div>
                <div>{lunch?.totalMealCount}</div>
            </div>)
            }
        </div>
    </div>
    )
}

export default ReportCard