import Http from "../../../service/http";

const getParsedData = (zones) => {
  return zones?.map((zone, i) => ({
    ...zone,
    no: i + 1,
    packageType: zone?.name,
    description: zone?.description,
  }));
};

export const getAllZoneType = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    const workspaceId = localStorage.getItem("tenantId");
    const data = await Http.get(`u/deliveryZone/workspace`, "", {
      token,
      workspaceId,
    });

    return getParsedData(data?.data);
  } catch (err) {
    console.log(err);
  }
};

function poller(fn, args, checkCondition, interval = 1000, timeout = 10000) {
  const startTime = Date.now();

  return new Promise((resolve, reject) => {
    const executePoll = async () => {
      // Call the function to execute
      const result = await fn(args);

      // Check if the result satisfies the condition
      if (checkCondition(result)) {
        return resolve(result);
      }

      // Check if timeout has been exceeded
      if (Date.now() - startTime >= timeout) {
        return reject(new Error("Polling timed out"));
      }

      // Schedule the next poll
      setTimeout(executePoll, interval);
    };

    // Start the polling
    executePoll();
  });
}

const pollAPi = async (reportId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const workspaceId = localStorage.getItem("tenantId");
    const data = await Http.get(`u/report/${reportId}`, "", {
      token,
      workspaceId,
    });
    return data?.data?.status;
  } catch (err) {
    console.log("Error in pollAPI:", err);
    return null; // return null to avoid errors in polling
  }
};

const getPresignedUrl = async (id) => {
  try {
    const token = localStorage.getItem("accessToken");
    const workspaceId = localStorage.getItem("tenantId");
    const data = await Http.get(`u/report/${id}/preSignedUrl`, "", {
      token,
      workspaceId,
    });
    const { preSignedUrl } = data?.data;
    return preSignedUrl;
  } catch (err) {
    console.log("Error in getPresignedUrl:", err);
    return null;
  }
};

const downloadFile = async (url) => {
  try {
    const token = localStorage.getItem("accessToken");
    const workspaceId = localStorage.getItem("tenantId");
    const response = await Http.get(url, "", { token, workspaceId });
    const blob = await response.blob();
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = "ORDER_LIST";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(downloadLink.href);
  } catch (err) {
    console.log("Error in downloadFile:", err);
  }
};

export const createReport = async (id) => {
  try {
    const token = localStorage.getItem("accessToken");
    const workspaceId = localStorage.getItem("tenantId");

    // Trigger report creation
    const data = await Http.post(
      `u/report/order-report`,
      {
        page: 0,
        size: 50,
        customerIdList: null,
        date: new Date(),
        mealType: "LUNCH",
        deliveryZoneId: id,
      },
      "",
      { token, workspaceId }
    );

    const { requestId } = data?.data;

    // Poll until report is ready
    await poller(
      pollAPi,
      requestId,
      (status) => status === "COMPLETED",
      1000,
      10000
    );
    console.log("nextt");
    // Get presigned URL and download the file
    const url = await getPresignedUrl(requestId);
    if (url) {
      downloadFile(url);
    } else {
      console.error("Failed to retrieve presigned URL");
    }
  } catch (err) {
    console.log("Error in createReport:", err);
  }
};
