import React, { useState } from "react";
import {
  FormControlLabel,
  FormGroup,
  Checkbox,
  Radio,
  RadioGroup,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import Food from "../../../public/Food.svg";
import Calander from "../../../public/calendar.svg";
import Info from "../../../public/Info.svg";
import styles from "./addSubscription.module.scss";
import Button from "../button/Button";
import MultiSelect from "../multiSelect/MutliSelect";
import { DAYS_OPTION } from "./addSubscription.helper";
import SingleSelect from "../SingleSelect/SingleSelect";
import PreviewSubscription from "../PreviewSubsription/PreviewSubsrciption";

const AddSubscription = (props) => {
  const {
    mealTypes,
    handleAddSubscription,
    mealTypeFrequency,
    customerId,
    handleCancel,
  } = props;

  const [showPreview, setShowPreview] = useState(false);

  const [input, setInput] = useState({
    applicableWeekdays: [],
    mealTypeSelected: [],
    startingMeal: "LUNCH",
    mealPlanId: "",
    totalNumberOfMeals: mealTypeFrequency[0]?.noOfMeals || 0,
    startDate: new Date(),
    customerId,
  });

  const handleOnSubmit = () => {
    setShowPreview(true);
  };

  const handleOnCancel = () => {
    setShowPreview(false);
  };

  const handleMealSelect = (type, value) => {
    setInput((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleDateChange = (date) => {
    setInput((prev) => ({
      ...prev,
      startDate: date,
    }));
  };

  const handleChange = (event) => {
    const { id, value, checked } = event.target;
    setInput((prev) => {
      if (id === "mealTypeSelected" || id === "applicableWeekdays") {
        let newValue = [...prev[id]];
        if (checked) {
          newValue.push(value);
        } else {
          newValue = newValue.filter((item) => item !== value);
        }
        return {
          ...prev,
          [id]: newValue,
        };
      } else {
        return {
          ...prev,
          [id]: value,
        };
      }
    });
  };

  return (
    <div className={styles.containerParent}>
      {!showPreview && (
        <div>
          <div className={styles.parentMain}>
            <div className={styles.divide}>
              <div className={styles.parent}>
                <div style={{ display: "flex", gap: "2rem" }}>
                  <img className={styles.img} src={Food} />
                  <div>
                    <div className={styles.heading}>Meal type</div>
                    <div>
                      <FormGroup id="mealType" row>
                        <FormControlLabel
                          value="LUNCH"
                          control={
                            <Checkbox
                              id="mealTypeSelected"
                              onChange={handleChange}
                              value="LUNCH"
                              checked={input.mealTypeSelected.includes("LUNCH")}
                            />
                          }
                          label="Lunch"
                        />
                        <FormControlLabel
                          value="DINNER"
                          control={
                            <Checkbox
                              id="mealTypeSelected"
                              onChange={handleChange}
                              value="DINNER"
                              checked={input.mealTypeSelected.includes(
                                "DINNER"
                              )}
                            />
                          }
                          label="Dinner"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.parent}>
                <div style={{ display: "flex", gap: "2rem" }}>
                  <img className={styles.img} src={Food} />
                  <div>
                    <div className={styles.heading}>Starting Date</div>
                    <div style={{ paddingTop: "1rem" }}>
                      <DatePicker
                        value={input.startDate} // Controlled value
                        inputFormat="MM/dd/yyyy"
                        onChange={handleDateChange}
                        name="startDate"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.divide}>
              <div className={styles.parent}>
                <div style={{ display: "flex", gap: "2rem" }}>
                  <img className={styles.img} src={Info} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.8rem",
                    }}
                  >
                    <div className={styles.heading}>Meal Package</div>
                    <div style={{ minWidth: "17rem" }}>
                      <SingleSelect
                        value={input.mealPlanId} // Controlled value
                        handleChange={handleMealSelect.bind(null, "mealPlanId")}
                        options={mealTypes.map((meal) => ({
                          label: meal?.name,
                          value: meal?.mealPlanId,
                        }))}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.parent}>
                <div style={{ display: "flex", gap: "2rem" }}>
                  <img className={styles.img} src={Info} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.8rem",
                    }}
                  >
                    <div className={styles.heading}>Meal Frequency</div>
                    <div style={{ minWidth: "17rem" }}>
                      <SingleSelect
                        value={input.totalNumberOfMeals} // Controlled value
                        handleChange={handleMealSelect.bind(
                          null,
                          "totalNumberOfMeals"
                        )}
                        options={mealTypeFrequency.map((meal) => ({
                          label: meal?.name,
                          value: meal?.noOfMeals,
                        }))}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.parent}>
              <div style={{ display: "flex", gap: "2rem" }}>
                <img className={styles.img} src={Food} />
                <div>
                  <div className={styles.heading}>Starting Meal</div>
                  <div>
                    <RadioGroup
                      value={input.startingMeal} // Controlled value
                      row
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="LUNCH"
                        control={<Radio id="startingMeal" />}
                        label="Lunch"
                      />
                      <FormControlLabel
                        value="DINNER"
                        control={<Radio id="startingMeal" />}
                        label="Dinner"
                      />
                    </RadioGroup>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.parent}>
              <div style={{ display: "flex", gap: "2rem" }}>
                <img className={styles.img} src={Calander} />
                <div>
                  <div className={styles.heading}>Days</div>
                  <FormGroup row>
                    {["MON", "TUES", "WED", "THURS", "FRI", "SAT", "SUN"].map(
                      (day) => (
                        <FormControlLabel
                          key={day}
                          value={day}
                          control={
                            <Checkbox
                              id="applicableWeekdays"
                              checked={input.applicableWeekdays.includes(day)}
                              onChange={handleChange}
                            />
                          }
                          label={day}
                        />
                      )
                    )}
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.footer}>
            <Button onClick={handleOnSubmit}>Preview & Save</Button>
            <Button onClick={handleCancel} color="tertairy">
              Cancel
            </Button>
          </div>
        </div>
      )}
      {showPreview && (
        <PreviewSubscription
          handleOnSubmit={handleAddSubscription}
          handleOnCancel={handleOnCancel}
          input={input}
        />
      )}
    </div>
  );
};

export default AddSubscription;
