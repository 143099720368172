import React, { useState } from "react";
import TableManager from "../../atoms/table/TableManager";
import {
  PACKAGE_TYPE_COLUMN,
  MEAL_DATA,
  ACTION_OPTIONS,
} from "./mealTypeColumns";
import Button from "../../atoms/button/Button";
import AddIcon from "@mui/icons-material/Add";
import styles from "./mealtypeSetup.module.scss";
import { addMealType, editMealType, getParsedData } from "./mealType.helper";
import BasicModal from "../../atoms/Modal/Modal";
import MealTypeForm from "../../atoms/mealTypeForm/MealTypeForm";
import KebabMenu from "../../atoms/kebabMenu/KebabMenu";

const MealTypeSetup = (props) => {
  const { meals = [], isLoading, init } = props;
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const data = getParsedData(meals);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showKebabMenu, setShowKebabMenu] = useState(false);
  const [mealTypes, setMealTypes] = useState(null);

  const handleOnSubmit = async (input) => {
    await addMealType(input);
    setShowModal(false);
    init();
  };

  const handleActionClick = (event) => {
    setAnchorEl(event?.currentTarget);
    setShowKebabMenu(true);
  };

  const rightSection = () => {
    return (
      <Button className={styles.addCustomer} onClick={() => setShowModal(true)}>
        <AddIcon /> <div>Add Meal type</div>
      </Button>
    );
  };

  const handleClose = () => {
    setShowKebabMenu(false);
  };

  const handleKebabMenuSelect = (id) => {
    console.log(id);
    if (id === "edit") {
      setShowEditModal(true);
    }
  };

  const handleRowClick = (row) => {
    setMealTypes({ ...row });
  };

  const handleOnEditSubmit = async (input) => {
    await editMealType(input, mealTypes?.mealPlanId);
    setShowEditModal(false);
    init();
  };

  return (
    <div>
      <TableManager
        isLoading={isLoading}
        rightSection={rightSection}
        handleRowClick={handleRowClick}
        handleActionClick={handleActionClick}
        data={data}
        headerName={"Meal Type Setup"}
        columns={PACKAGE_TYPE_COLUMN}
        showAction={true}
        showPagination={false}
      />
      <KebabMenu
        handleKebabMenuSelect={(id) => handleKebabMenuSelect(id)}
        options={ACTION_OPTIONS}
        handleClose={handleClose}
        anchorEl={anchorEl}
        showKebabMenu={showKebabMenu}
      />
      <BasicModal
        headingClassName={styles.headingClassName}
        heading={"Add Meal Type"}
        handleModalClose={() => setShowModal(false)}
        ChildComponent={() => (
          <MealTypeForm
            handleOnCancel={() => setShowModal(false)}
            handleOnSubmit={handleOnSubmit}
          />
        )}
        isModalOpen={showModal}
      />
      <BasicModal
        headingClassName={styles.headingClassName}
        heading={"Edit Meal Type"}
        handleModalClose={() => setShowEditModal(false)}
        ChildComponent={() => (
          <MealTypeForm
            handleOnCancel={() => setShowEditModal(false)}
            handleOnSubmit={handleOnEditSubmit}
            mealTypes={mealTypes}
          />
        )}
        isModalOpen={showEditModal}
      />
    </div>
  );
};

export default MealTypeSetup;
