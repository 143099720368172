export const COLUMNS = [
  {
    headerName: "Customer Name",
    id: "customerName",
    accessor: "customerName",
  },
  {
    headerName: "Meal Plan Name",
    id: "mealPlanName",
    accessor: "mealPlanName",
  },
  {
    headerName: "Meal Type",
    id: "mealType",
    accessor: "mealType",
  },
  {
    headerName: "Date",
    id: "date",
    accessor: "date",
  },
  {
    headerName: "Status",
    id: "status",
    accessor: "status",
  },
  {
    headerName: "Customer Email",
    id: "customerEmail",
    accessor: "customerEmail",
  },
  {
    headerName: "Customer Address",
    id: "customerAddress",
    accessor: "customerAddress",
  },
];

export const ACTION_OPTIONS = [
  {
    label: "Skip Meal",
    key: "skipMeal",
  },
];
