import React from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LOGO from "../../public/Logo.png";
import styles from "./support.module.scss";
import googleImg from "../../public/googleMap.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const Support = () => {
  return (
    <div className={styles.parent}>
      <div className={styles.container}>
        <div className={styles.heading}>Contact Us</div>
        <div className={styles.textHelp}>
          We always ensure that our restaurant partners are heard & their
          problems resolved. We look forward to hearing from you!
        </div>
        <div className={styles.parentContainer}>
          <div className={styles.boxContainer}>
            <LocalPhoneIcon fontSize="large" className={styles.logoIcon} />
            <div>
              <div className={styles.headings}>Phone Call</div>
              <div>+91 94612 14514</div>
            </div>
          </div>
          <div className={styles.boxContainer}>
            <EmailIcon fontSize="large" className={styles.logoIcon} />
            <div>
              <div className={styles.headings}>Mail us at</div>
              <div>hello.mealios@gmail.com</div>
            </div>
          </div>
          <div className={styles.boxContainer}>
            <LinkedInIcon fontSize="large" className={styles.logoIcon} />
            <div>
              <div className={styles.headings}>LinkedIn</div>
              <div>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/mealios"
                >
                  Mealios
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.address}>
          <img className={styles.googleImg} src={googleImg} />
          <div className={styles.textAddress}>
            <div className={styles.headings}>Registered Office Address</div>
            <div style={{ lineHeight: "2rem" }}>
              S. No. 42/5, SJR - Zion, Near Kundalahalli Gate Signal, opp.
              Shankar Eye Hospital, next to Golcanda Chimney Resturant,
              Munnekollal, Varthur, Karnataka 560037.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
