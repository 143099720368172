import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";

import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SupportIcon from "@mui/icons-material/Support";
import Http from "../../service/http";

export const MENU_OPTIONS = [
  { label: "Home", value: "home", icon: HomeOutlinedIcon },
  {
    label: "Customer Management",
    value: "customerManagement",
    icon: PersonOutlineIcon,
  },
  {
    label: "Order Management",
    value: "orderManagement",
    icon: AssessmentOutlinedIcon,
  },
  { label: "Account", value: "account", icon: AccountCircleIcon },
  { label: "Settings", value: "settings", icon: SettingsIcon },
  { label: "Support", value: "support", icon: SupportIcon },
];

export const getUserDetails = async () => {
  const res = await Http.get("/u/user/details");
  return res?.data;
};

export const getDefaultSelectedUrl = (history) => {
  const idArray = history?.location?.pathname?.split("/");
  return idArray[1];
};
