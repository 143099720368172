import React, { useState } from "react";
import { FormControl } from "@mui/material";
import TextFieldRenderer from "../textFieldRenderer/TextFieldRenderer";
import styles from "./frequencyType.module.scss";
import Button from "../button/Button";

const FrequencyTypeForm = (props) => {
  const { handleOnSubmit, handleOnCancel, freqTypes } = props;
  const { discountPercentage, frequency, frequencyType } = freqTypes || {};

  const [input, setInput] = useState({
    name: frequencyType,
    noOfMeals: frequency,
    discountPercentage,
  });

  const handleOnChange = (event) => {
    const { name, value } = event?.target || {};
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className={styles.container}>
      <FormControl sx={{ width: "100%" }}>
        <div className={styles.parent}>
          <TextFieldRenderer
            defaultValue={frequencyType}
            onChange={handleOnChange}
            placeholder="Frequency Type"
            name="name"
          />
          <TextFieldRenderer
            defaultValue={frequency}
            onChange={handleOnChange}
            type="number"
            placeholder="Frequency"
            name="noOfMeals"
          />
          <TextFieldRenderer
            defaultValue={discountPercentage}
            onChange={handleOnChange}
            type="number"
            placeholder="Discount"
            name="discountPercentage"
          />
        </div>
      </FormControl>
      <div className={styles.footer}>
        <Button onClick={handleOnCancel} color="tertairy">
          Cancel
        </Button>
        <Button onClick={() => handleOnSubmit(input)}>Save</Button>
      </div>
    </div>
  );
};

export default FrequencyTypeForm;
