import React, { useState } from "react";
import { FormControl, InputAdornment } from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import TextFieldRenderer from "../textFieldRenderer/TextFieldRenderer";
import styles from "./profileForm.module.scss";
import Button from "../button/Button";

const ProfileForm = (props) => {
  const { userDetails, handleOnCancel, handleOnSubmit } = props;
  const { firstName, lastName, email, phoneNumber } = userDetails;
  const [input, setInput] = useState({
    firstName,
    lastName,
    email,
    phoneNumber,
  });

  const handleOnChange = (event) => {
    const { name, value } = event?.target || {};
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className={styles.parent}>
      <div className={styles.container}>
        <FormControl sx={{ width: "100%" }}>
          <div className={styles.firstPart}>
            <TextFieldRenderer
              onChange={handleOnChange}
              defaultValue={firstName}
              placeholder="First Name"
              name="firstName"
              labelText="First Name"
            />
            <TextFieldRenderer
              onChange={handleOnChange}
              defaultValue={lastName}
              placeholder="Last Name"
              name="lastName"
              labelText="Last Name"
            />
          </div>
          <div className={styles.firstPart}>
            <TextFieldRenderer
              onChange={handleOnChange}
              name="email"
              defaultValue={email}
              placeholder="Email"
              disabled
              labelText="Email"
            />
            <TextFieldRenderer
              onChange={handleOnChange}
              name="phoneNumber"
              defaultValue={phoneNumber}
              placeholder="Phone number"
              labelText="Phone number"
            />
          </div>
          {/* <div className={styles.firstPart}>
            <TextFieldRenderer type="password" placeholder="Old Password" />
            <TextFieldRenderer type="password" placeholder="New Password" />
            <TextFieldRenderer type="password" placeholder="Confirm Password" />
            </div> */}
          {/* <div className={styles.thirdPart}>
            <TextFieldRenderer
              onChange={handleOnChange}
              name="city"
              placeholder="City"
            />
            <TextFieldRenderer
              onChange={handleOnChange}
              name="state"
              placeholder="State"
            />
            <TextFieldRenderer
              onChange={handleOnChange}
              name="country"
              placeholder="Country"
            />
          </div> */}
        </FormControl>
      </div>
      <div className={styles.footer}>
        <Button onClick={handleOnCancel} color="tertairy">
          Cancel
        </Button>
        <Button onClick={() => handleOnSubmit(input)}>Save</Button>
      </div>
    </div>
  );
};

export default ProfileForm;
