import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DateRangeIcon from "@mui/icons-material/DateRange";
import BasicModal from "../../components/atoms/Modal/Modal";
import styles from "./profile.module.scss";
import ProfileForm from "../../components/atoms/Profileform/Profileform";
import PasswordForm from "../../components/atoms/PasswordForm/PasswordForm";
import {
  changeUserPassword,
  getUserDetails,
  updateUserDetails,
} from "./profile.helper";
import Loader from "../../components/atoms/Loader/Loader";

const Profile = (props) => {
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [showEditPasswordeModal, setShowEditPasswordModal] = useState(false);

  useEffect(async () => {
    const details = await getUserDetails();
    setUserDetails(details);
    setIsLoading(false);
  }, []);

  const handleOnProfileSubmit = async (payload) => {
    await updateUserDetails(payload);
    setShowEditProfileModal(false);
  };

  const handleOnPasswordSubmit = async (payload) => {
    await changeUserPassword(payload);
    setShowEditPasswordModal(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.heading}>Profile</div>
      {isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <>
          <div className={styles.customer}>
            <div c>
              {userDetails?.firstName} {userDetails?.lastName}
            </div>
            <div className={styles.editDetails}>
              <div
                onClick={() => setShowEditProfileModal(true)}
                className={styles.edit}
              >
                <EditIcon />
                Edit Profile
              </div>
              <div
                onClick={() => setShowEditPasswordModal(true)}
                className={styles.edit}
              >
                <EditIcon />
                Update Password
              </div>
            </div>
          </div>
          <div className={styles.information}>
            <div className={styles.content}>
              <div className={styles.icon}>
                <LocalPhoneIcon fontSize="large" />
              </div>
              <div className={styles.text}>
                <div>Phone No.</div>
                <div>{userDetails?.phoneNumber}</div>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.icon}>
                <EmailIcon fontSize="large" />
              </div>
              <div className={styles.text}>
                <div>Email</div>
                <div>{userDetails?.email}</div>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.icon}>
                <LocationOnIcon fontSize="large" />
              </div>
              <div className={styles.text}>
                <div>Location</div>
                <div>India</div>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.icon}>
                <DateRangeIcon fontSize="large" />
              </div>
              <div className={styles.text}>
                <div>Date Of Birth</div>
                <div>19-09-1999</div>
              </div>
            </div>
          </div>
          <BasicModal
            headingClassName={styles.headingClassName}
            heading={"Edit Profile"}
            handleModalClose={() => setShowEditProfileModal(false)}
            ChildComponent={() => (
              <ProfileForm
                handleOnCancel={() => setShowEditProfileModal(false)}
                handleOnSubmit={handleOnProfileSubmit}
                userDetails={userDetails}
              />
            )}
            isModalOpen={showEditProfileModal}
          />
          <BasicModal
            headingClassName={styles.headingClassName}
            heading={"Update Password"}
            handleModalClose={() => setShowEditPasswordModal(false)}
            ChildComponent={() => (
              <PasswordForm
                handleOnCancel={() => setShowEditPasswordModal(false)}
                handleOnSubmit={handleOnPasswordSubmit}
                userDetails={userDetails}
              />
            )}
            isModalOpen={showEditPasswordeModal}
          />
        </>
      )}
    </div>
  );
};

export default Profile;
