import React, { useEffect, useState } from "react";

import Header from "../../components/molecules/header/Header";
import SideBar from "../../components/organisms/sideBar/SideBar";
import {
  MENU_OPTIONS,
  getUserDetails,
  getDefaultSelectedUrl,
} from "./foodie.helper";

import styles from "./foodie.module.scss";
import { setDefaultModuleService } from "../../service/http";

const Foodie = (props) => {
  const { history } = props;

  const [showSideBar, setSideBar] = useState(false);
  const [userData, setUserData] = useState({});
  const defaultSelectedUrl = getDefaultSelectedUrl(history);

  useEffect(async () => {
    setDefaultModuleService();
    const data = await getUserDetails();
    setUserData(data);
  }, []);

  const handleMenuOptionClick = (value) => {
    history.replace(`/${value}`);
  };

  const workspaceDetails = {
    workspaceName: localStorage.getItem("workspaceName"),
    workspaceLogo: localStorage.getItem("workspaceLogo"),
  };

  const handleMenuClick = () => {
    setSideBar((prev) => !prev);
  };

  return (
    <div>
      <div className={styles.container}>
        <div
          className={`${styles.sideBar} ${showSideBar && styles.toggleSideBar}`}
        >
          <SideBar
            defaultOption={defaultSelectedUrl}
            onClick={handleMenuOptionClick}
            menuOptions={MENU_OPTIONS}
            handleMenuClick={handleMenuClick}
          />
        </div>

        <div className={styles.header}>
          <Header
            workspaceDetails={workspaceDetails}
            userData={userData}
            history={props?.history}
            handleMenuClick={handleMenuClick}
          />
        </div>
      </div>
      {/* <div style={{ display: "flex", paddingTop: "4rem", gap: "3rem" }}>
        <img
          src={workspaceDetails?.workspaceLogo}
          className={styles.businessLogo}
        />
        <div className={styles.businessName}>
          {workspaceDetails?.workspaceName}
        </div>
      </div> */}
    </div>
  );
};

export default Foodie;
