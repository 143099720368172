import Http from "../../service/http";

const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const daysToDisplayed = [-3, -2, -1, 0, 1, 2, 3];
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const getDailyReports = async (date) => {
  try {
    const response = await Http.get(`u/order/daySummary?date=${date}`);
    return response?.data;
  } catch (err) {
    return console.log(err);
  }
};

export const getOptions = (curentDate = new Date()) => {
  return daysToDisplayed?.map((val, i) => {
    const date = new Date();
    date.setDate(date.getDate() + val);
    const month = monthNames[date.getMonth()];
    const currentDate = date.getDate();
    const localDate = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const formattedDate = localDate.split("/");
    return {
      label: `${currentDate} ${month}`,
      id: `${formattedDate[2]}-${formattedDate[1]}-${formattedDate[0]}`,
      defaultSelected: val === 0,
    };
  });
};

export const getDefaultSelectedId = (options) => {
  const option = options?.filter((option) => option?.defaultSelected);
  return option[0]?.id;
};
