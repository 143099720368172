import Http from "../../service/http";

export const getParsedData = (orders) => {
  return orders?.map((order) => ({
    ...order,
    mealPlanName: order?.mealPlanName,
    mealType: order?.mealType,
    date: `${order?.date[2]}-${order?.date[1]}-${order?.date[0]}`,
    status: order?.status,
    customerName: order?.customerFirstName + " " + order?.customerLastName,
    customerEmail: order?.customerEmail,
    customerAddress:
      order?.customerAddress?.line1 +
      "," +
      order?.customerAddress?.line2 +
      "," +
      order?.customerAddress?.city,
  }));
};

export const getOrderDetails = async (type, date) => {
  try {
    const token = localStorage.getItem("accessToken");
    const workspaceId = localStorage.getItem("tenantId");
    const response = await Http.post(
      `u/order/search`,
      {
        page: 0,
        size: 50,
        customerId: "",
        date,
        mealType: type,
      },
      "",
      {
        token,
        workspaceId,
      }
    );

    const parsedData = getParsedData(response?.data?.data);
    return parsedData;
  } catch (err) {
    console.log(err);
  }
};

export const skipOrder = async (subscriptionId, orderId) => {
  try {
    const token = localStorage.getItem("accessToken");
    const workspaceId = localStorage.getItem("tenantId");
    await Http.put(
      `/u/mealSubscription/${subscriptionId}/skipOrder?orderId=${orderId}`,
      [],
      "",
      { token, workspaceId }
    );
  } catch (err) {
    console.log(err);
  }
};
