export const PACKAGE_TYPE_COLUMN = [
  {
    headerName: "Sr No",
    id: "no",
  },
  {
    headerName: "Package Type",
    id: "packageType",
  },
  {
    headerName: "Base Price(Rs)",
    id: "basePrice",
  },
  {
    headerName: "Description",
    id: "description",
  },
];

export const ACTION_OPTIONS = [
  {
    label: "Edit",
    key: "edit",
  },
  {
    label: "Delete",
    key: "delete",
  },
];
