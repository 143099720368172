import React, { useEffect, useState } from "react";
import Button from "../button/Button";
import styles from "./excelModal.module.scss";
import { createReport, getAllZoneType } from "./excel.helper";
import SingleSelect from "../SingleSelect/SingleSelect";

const ExcelModal = (props) => {
  const { handleModalClose, handleSubmit } = props;
  const [zoneType, setZoneTypes] = useState([]);
  const [zoneId, setZoneId] = useState();

  const init = async () => {
    const response = await getAllZoneType();
    setZoneTypes(response);
  };

  useEffect(() => {
    init();
  }, []);

  const handleZoneSelect = (id) => {
    setZoneId(id);
  };

  const handleDownLoad = async () => {
    await createReport(setZoneId);
    handleSubmit();
  };
  return (
    <div className={styles.parent}>
      <SingleSelect
        label="Zone Type"
        handleChange={handleZoneSelect}
        options={zoneType?.map((zone) => ({
          label: zone?.packageType,
          value: zone?.zoneId,
        }))}
      />
      <div className={styles.footer}>
        <Button onClick={handleModalClose} color="tertairy">
          Cancel
        </Button>
        <Button onClick={handleDownLoad}>Dowload Excel</Button>
      </div>
    </div>
  );
};

export default ExcelModal;
