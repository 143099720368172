import React from "react";
import ReactDOM from "react-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Input from "@mui/material/Input";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
// Icons
import EditIcon from "@mui/icons-material/EditOutlined";
import DoneIcon from "@mui/icons-material/DoneAllTwoTone";
import RevertIcon from "@mui/icons-material/NotInterestedOutlined";

const createData = (name, calories, fat, carbs, protein) => ({
  id: name.replace(" ", "_"),
  name,
  calories,
  fat,
  carbs,
  protein,
  isEditMode: false,
});

const CustomTableCell = ({ row, name, onChange }) => {
  const classes = {};
  const { isEditMode } = row;
  return (
    <TableCell
      sx={{ background: "white" }}
      align="left"
      className={classes.tableCell}
    >
      {isEditMode ? (
        <Input
          type="currency"
          value={row[name]}
          name={name}
          onChange={(e) => onChange(e, row)}
          className={classes.input}
        />
      ) : (
        row[name]
      )}
    </TableCell>
  );
};

function InputTable(props) {
  const { columns, heading, data } = props;
  const [rows, setRows] = React.useState(data);
  const [previous, setPrevious] = React.useState({});
  const classes = {};

  const onToggleEditMode = (id) => {
    setRows((state) => {
      return rows.map((row) => {
        if (row?.no === id + 1) {
          return { ...state, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious((state) => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
  };

  const onRevert = (id) => {
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious((state) => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
  };

  return (
    <Paper className={classes.root}>
      <div style={{ fontSize: "2rem", padding: "1rem 3rem" }}>{heading}</div>
      <Table sx={{ background: "red" }} aria-label="caption table">
        <TableHead>
          <TableRow>
            {columns?.map((column) => (
              <TableCell sx={{ background: "white" }} align="left">
                {column?.label}
              </TableCell>
            ))}
            <TableCell sx={{ background: "white" }} />
          </TableRow>
        </TableHead>
        <TableBody sx={{ background: "white" }}>
          {rows?.map((row, i) => (
            <TableRow key={i}>
              {Object.keys(row)?.map((id) => {
                if (id === "no")
                  return (
                    <TableCell sx={{ background: "white" }} align="left">
                      {row[id]}
                    </TableCell>
                  );
                return <CustomTableCell {...{ row, name: id, onChange }} />;
              })}

              <TableCell
                sx={{ background: "white" }}
                className={classes.selectTableCell}
              >
                {row?.isEditMode ? (
                  <>
                    <IconButton
                      aria-label="done"
                      onClick={() => {
                        onToggleEditMode(i);
                      }}
                    >
                      <DoneIcon />
                    </IconButton>
                    <IconButton aria-label="revert" onClick={() => onRevert(i)}>
                      <RevertIcon />
                    </IconButton>
                  </>
                ) : (
                  <IconButton
                    aria-label="delete"
                    onClick={() => onToggleEditMode(i)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default InputTable;
