import * as React from "react";
import cx from "classnames";
import styles from "./sideBar.module.scss";
import Icon from "@mui/material/Icon";
import TransLogo from "../../../public/TransLogo.png";

export default function SideBar(props) {
  const { menuOptions, onClick, defaultOption, handleMenuClick } = props;
  let defaultSelected = defaultOption;
  if (defaultOption === "login") {
    defaultSelected = "home";
  }
  const [value, setValue] = React.useState(
    defaultSelected || menuOptions[0]?.value
  );

  const handleClick = (event) => {
    handleMenuClick();
    setValue(event);
    onClick(event);
  };

  return (
    <div className={styles.container}>
      <img className={styles.logo} src={TransLogo} />
      {menuOptions?.map((option) => {
        const selectedClass = cx({
          [styles.tab]: true,
          [styles.selected]: option?.value === value,
        });
        return (
          <div
            className={cx(selectedClass, styles.tabs)}
            onClick={() => handleClick(option?.value)}
            id={option?.value}
            role="button"
          >
            <div style={{ display: "flex" }}>
              <Icon
                style={{ fontSize: "2.2rem" }}
                className={styles.iconClassName}
                component={option?.icon}
              />
              <div style={{ paddingTop: "0.4rem" }}>{option?.label}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
