import React, { useEffect, useState, useMemo } from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import ReportCard from "../../components/atoms/ReportCard/ReportCard";
import {
  getDailyReports,
  getOptions,
  getDefaultSelectedId,
} from "./dailyReport.helper";
import SelectInput from "../../components/atoms/SelectInput/SelectInput";
import Loader from "../../components/atoms/Loader/Loader";
import styles from "./dailyReport.module.scss";

const DailyReports = (props) => {
  const { history } = props;
  const options = useMemo(getOptions, []);
  const [removeSelectedItem, setRemoveSelectedItem] = useState(false);

  const defaultSelectedId = useMemo(
    () => getDefaultSelectedId(options),
    [options]
  );
  const [date, setDate] = useState(defaultSelectedId);

  const [reports, setReports] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const handleDateChange = (val) => {
    setDate(moment(val).format("YYYY-MM-DD"));
    setRemoveSelectedItem(true);
  };

  const handleDateSelect = (date) => {
    setDate(date);
    setRemoveSelectedItem(false);
  };

  const handleOrderClick = (type) => {
    history.push(`/orderManagement/orders/${type}/${date}`);
  };

  useEffect(async () => {
    setIsLoading(true);
    const dailyReports = await getDailyReports(date);
    const { summaryData } = dailyReports || {};
    setReports(summaryData);
    setIsLoading(false);
  }, [date]);

  return (
    <div className={styles.container}>
      <div style={{ fontSize: "2.6rem", paddingTop: "2rem" }}>
        Meals To Prepare
      </div>
      <div className={styles.dateContainer}>
        <SelectInput
          handleSelect={handleDateSelect}
          defaultSelectedId={defaultSelectedId}
          options={options}
          removeSelectedItem={removeSelectedItem}
        />
        <DatePicker
          value={null}
          inputFormat="MM/dd/yyyy"
          size="medium"
          onChange={handleDateChange}
          name="startDate"
          sx={{ width: "13rem", fontSize: "20rem" }}
        />
      </div>
      {isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <ReportCard
          date={date}
          reports={reports}
          handleOrderClick={handleOrderClick}
        />
      )}
    </div>
  );
};

export default DailyReports;
