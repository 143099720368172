import React, { useEffect, useState } from "react";
import TableManager from "../../atoms/table/TableManager";
import { PREVIEW_COLUMN } from "./previewColumn";
import { getParedData, getPreview } from "./preview.helper";
import Button from "../button/Button";
import styles from "./preview.module.scss";

const PreviewSubsription = (props) => {
  const { input, handleOnCancel, handleOnSubmit } = props;
  const [previewData, setPreviewData] = useState([]);

  const init = async () => {
    const res = await getPreview(input);
    setPreviewData(getParedData(res));
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className={styles.container}>
      <div style={{ maxHeight: "30rem" }}>
        <TableManager
          isLoading={false}
          data={previewData}
          columns={PREVIEW_COLUMN}
          pagination={true}
          headerClass={styles.headerClass}
        />
      </div>
      <div className={styles.footer}>
        <Button onClick={handleOnCancel} color="tertairy">
          Cancel
        </Button>
        <Button onClick={() => handleOnSubmit(input)}>Save</Button>
      </div>
    </div>
  );
};

export default PreviewSubsription;
