import { createTheme } from "@mui/material";
import COLORS from "./color.constant";
import { color } from "@mui/system";

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.IORANGE,
    },
    secondary: {
      main: COLORS.GREY,
    },
    tertairy: {
      main: COLORS.BLACK,
      contrastText: "white",
    },
  },
  typography: {
    fontSize: "6rem",
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          borderRadius: "16px",
          textTransform: "none",
          fontSize: "1.4rem",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "4rem",
          height: "3.6rem",
          fontSize: "1.2rem",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#a3a3a3",
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "2.2px",
              borderColor: "#a3a3a3",
            },
          },
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },

    MuiTable: {
      styleOverrides: {
        root: {
          fontSize: "3rem",
          borderCollapse: "seperate",
          borderSpacing: "0 6px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "1.4rem",
          border: "none",
          background: "#fbf6f4",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            fontSize: 22,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            fontSize: 22,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "1.4rem",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          border: "none",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
  },
});

export default theme;
