import React from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import styles from './customerCard.module.scss';


const CustomerCard = (props) => {
    const { customerDetails } = props
   
    return <div className={styles.container}>
        <div className={styles.profile}><AccountCircleIcon fontSize="large" /><div >{customerDetails?.firstName+" "+ customerDetails?.lastName}</div></div>
        <div className={styles.info}>
            <div className={styles.parent}>
                <div className={styles.heading}>Email</div>
                <div>{customerDetails?.email}</div>
            </div>
            <div className={styles.parent}>
                <div className={styles.heading}>Phone</div>
                <div>{customerDetails?.phoneNumber}</div>
            </div>
            <div className={styles.parent}>
                <div className={styles.heading}>Address</div>
                <div>{customerDetails?.address?.line1},{customerDetails?.address?.line2},{customerDetails?.address?.city},{customerDetails?.address?.country}</div>
            </div>
        </div>
    </div>
}

export default CustomerCard