import React, { useState } from "react";
import TableManager from "../../atoms/table/TableManager";
import { ACTION_OPTIONS, FREQUENCY_TYPE } from "./frequencyColumns";
import Button from "../../atoms/button/Button";
import AddIcon from "@mui/icons-material/Add";
import styles from "./mealtypeSetup.module.scss";
import {
  addFrequencyType,
  getParsedData,
  editFrequencyType,
} from "./frequency.helper";
import FrequencyTypeForm from "../../atoms/frequencyTypeForm/FrequencyTypeForm";
import BasicModal from "../../atoms/Modal/Modal";
import KebabMenu from "../../atoms/kebabMenu/KebabMenu";

const FrequencyTypeSetup = (props) => {
  const { frequency, isLoading, init } = props;
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const data = getParsedData(frequency);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showKebabMenu, setShowKebabMenu] = useState(false);
  const [freqTypes, setFreqTypes] = useState(null);

  const handleOnSubmit = async (input) => {
    await addFrequencyType(input);
    setShowModal(false);
  };

  const handleActionClick = (event) => {
    setAnchorEl(event?.currentTarget);
    setShowKebabMenu(true);
  };

  const handleClose = () => {
    setShowKebabMenu(false);
  };

  const handleKebabMenuSelect = (id) => {
    if (id === "edit") {
      setShowEditModal(true);
    }
  };

  const handleRowClick = (row) => {
    setFreqTypes({ ...row });
  };

  const handleOnEditSubmit = async (input) => {
    await editFrequencyType(input, freqTypes?.mealFrequencyId);
    setShowEditModal(false);
    init();
  };

  const rightSection = () => {
    return (
      <Button className={styles.addCustomer} onClick={() => setShowModal(true)}>
        {" "}
        <AddIcon /> <div>Add Frequency type</div>
      </Button>
    );
  };

  return (
    <div>
      <TableManager
        handleActionClick={handleActionClick}
        handleRowClick={handleRowClick}
        isLoading={isLoading}
        rightSection={rightSection}
        data={data}
        headerName={"Frequency Type Setup"}
        columns={FREQUENCY_TYPE}
        showAction={true}
        showPagination={false}
      />
      <KebabMenu
        handleKebabMenuSelect={(id) => handleKebabMenuSelect(id)}
        options={ACTION_OPTIONS}
        handleClose={handleClose}
        anchorEl={anchorEl}
        showKebabMenu={showKebabMenu}
      />
      <BasicModal
        headingClassName={styles.headingClassName}
        heading={"Add Frequency Type"}
        handleModalClose={() => setShowModal(false)}
        ChildComponent={() => (
          <FrequencyTypeForm
            handleOnSubmit={handleOnSubmit}
            handleOnCancel={() => setShowModal(false)}
          />
        )}
        isModalOpen={showModal}
      />
      <BasicModal
        headingClassName={styles.headingClassName}
        heading={"Edit Frequency Type"}
        handleModalClose={() => setShowEditModal(false)}
        ChildComponent={() => (
          <FrequencyTypeForm
            handleOnCancel={() => setShowEditModal(false)}
            handleOnSubmit={handleOnEditSubmit}
            freqTypes={freqTypes}
          />
        )}
        isModalOpen={showEditModal}
      />
    </div>
  );
};

export default FrequencyTypeSetup;
