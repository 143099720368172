import React, { useEffect, useState } from "react";
import CustomerCard from "../../components/atoms/customerCard/CustomerCard";
import Button from "../../components/atoms/button/Button";
import AddIcon from "@mui/icons-material/Add";

import PlanCard from "../../components/atoms/planCard/PlanCard";
import BasicModal from "../../components/atoms/Modal/Modal";
import AddSubscription from "../../components/atoms/AddSubscription/AddSubscription";
import styles from "./subscriptionManagement.module.scss";
import {
  getInitialDetails,
  getId,
  AddSubscriptionDetails,
  setSubscription,
} from "./subscription.helper";
import Loader from "../../components/atoms/Loader/Loader";
import ResumeSubscription from "../../components/atoms/resumeSubscriptionForm/ResumeSubscriptionForm";

const SubscriptionManagement = (props) => {
  const { history } = props;
  const url = history?.location?.pathname;
  const customerId = getId(url);
  const [isLoading, setIsLoading] = useState(true);

  const [customer, setCustomer] = useState({});
  const [plans, setPlans] = useState([]);
  const [mealTypes, setMealTypes] = useState([]);
  const [mealTypeFrequency, setMealTypeFrequency] = useState([]);
  const [showResumeForm, setResumeForm] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const init = async (customerId) => {
    const { customerDetails, allPlans, mealTypePlans, mealFrequency } =
      await getInitialDetails(customerId);
    setCustomer(customerDetails);
    setPlans(allPlans);
    setMealTypes(mealTypePlans);
    setMealTypeFrequency(mealFrequency);
    setIsLoading(false);
  };

  useEffect(async () => {
    init(customerId);
  }, [customerId]);

  const [showSubscriptionModal, setShowSubsriptionModal] = useState(false);

  const handleKebabMenuSelect = async (plan, id) => {
    setSelectedId(id);
    if (plan === "resume") {
      return setResumeForm(true);
    }
    await setSubscription(id, plan);
    init(customerId);
  };

  const handleAddSubscription = async (input) => {
    const paylaod = { ...input };
    await AddSubscriptionDetails(paylaod);
    setShowSubsriptionModal(false);
    init(customerId);
  };

  const handleResumeSubscription = async (input) => {
    await setSubscription(selectedId, "resume", input);
    setResumeForm(false);
    init(customerId);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.heading}>Subscription Management</div>
        <div>
          <Button
            onClick={() => setShowSubsriptionModal(true)}
            color="tertairy"
          >
            {" "}
            <AddIcon sx={{ marginRight: "0.4rem" }} /> Add Subscription
          </Button>
        </div>
      </div>
      {isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <>
          <CustomerCard customerDetails={customer} />
          <div className={styles.plans}>Plans</div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            {plans?.length === 0 ? (
              <div className={styles.noPlans}> No Plans Found :)</div>
            ) : (
              plans?.map((plan, i) => (
                <PlanCard
                  handleKebabMenuSelect={handleKebabMenuSelect}
                  index={i}
                  plan={plan}
                />
              ))
            )}
          </div>
        </>
      )}
      <BasicModal
        headingClassName={styles.headingClass}
        ChildComponent={() => (
          <AddSubscription
            customerId={customerId}
            x
            handleAddSubscription={handleAddSubscription}
            mealTypes={mealTypes}
            mealTypeFrequency={mealTypeFrequency}
            handleCancel={() => setShowSubsriptionModal(false)}
          />
        )}
        handleModalClose={() => setShowSubsriptionModal(false)}
        isModalOpen={showSubscriptionModal}
        heading="Add Subscription"
      />
      <BasicModal
        headingClassName={styles.headingClass}
        ChildComponent={() => (
          <ResumeSubscription
            handleOnCancel={() => setResumeForm(false)}
            handleOnSubmit={handleResumeSubscription}
          />
        )}
        handleOnCancel={() => setResumeForm(false)}
        isModalOpen={showResumeForm}
        heading="Resume Subscription"
      />
    </div>
  );
};

export default SubscriptionManagement;
