import React, { useState } from "react";
import cx from "classnames";
import styles from "./selectInput.module.scss";

const SelectInput = (props) => {
  const { options, defaultSelectedId, handleSelect, removeSelectedItem } =
    props;

  const [selectedId, setSelectedId] = useState(defaultSelectedId);

  const handleClick = (ev) => {
    const id = ev?.target?.id;
    setSelectedId(id);
    handleSelect(id);
  };

  return (
    <div className={styles.parent}>
      {options?.map((option) => (
        <div
          className={cx(
            styles.item,
            !removeSelectedItem && {
              [styles.selected]: selectedId == option.id,
            }
          )}
          onClick={handleClick}
          id={option?.id}
        >
          {option?.label}
        </div>
      ))}
    </div>
  );
};

export default SelectInput;
