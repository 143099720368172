import apiServices from "./apiServices";

const createHTTP = (baseUrl, headers, additional) => {
  //we can add interceptor here
  const axiosHttp = apiServices(baseUrl, headers, additional);
  axiosHttp.interceptors.request.use(
    (config) => config,
    (error) => {
      console.log(error, "err");
      return Promise.reject(error);
    }
  );

  axiosHttp.interceptors.response.use(
    (response) => {
      //const url = response.config.url;

      //setLocalStorageToken(token);
      return response;
    },
    (error) => {
      console.log(error);

      return Promise.reject(error);
    }
  );

  return axiosHttp;
};

export default createHTTP;
