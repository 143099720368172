import Http from "../../../service/http";

export const getParsedData = (frequency) =>
  frequency?.map((freq, i) => ({
    ...freq,
    no: i + 1,
    frequencyType: freq?.name,
    frequency: freq?.noOfMeals,
    discountPercentage: freq?.discountPercentage,
  }));

export const addFrequencyType = async (payload) => {
  try {
    const token = localStorage.getItem("accessToken");
    const workspaceId = localStorage.getItem("tenantId");
    await Http.post("/u/mealFrequency", payload, "", { token, workspaceId });
  } catch (err) {
    console.log(err);
  }
};

export const editFrequencyType = async (payload, id) => {
  try {
    const token = localStorage.getItem("accessToken");
    const workspaceId = localStorage.getItem("tenantId");
    await Http.put(`/u/mealFrequency/${id}`, payload, "", {
      token,
      workspaceId,
    });
  } catch (err) {
    console.log(err);
  }
};
