import React, { useState } from "react";
import { FormControl, InputAdornment } from "@mui/material";

import TextFieldRenderer from "../../atoms/textFieldRenderer/TextFieldRenderer";
import Button from "../../atoms/button/Button";
import LOGO from "../../../public/Logo.png";
import EmailIcon from "@mui/icons-material/Email";
import HttpsIcon from "@mui/icons-material/Https";
import FavoriteIcon from "@mui/icons-material/Favorite";
import styles from "./loginForm.module.scss";
import { margin, textAlign } from "@mui/system";

const LoginForm = (props) => {
  const { handleLogInClick, error } = props;
  const [input, setIput] = useState({ email: null, password: null });

  const handleChange = (event) => {
    const { value, name } = event?.target || {};
    setIput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isDisabled = () => input.email !== null && input.password !== null;

  const onSubmit = () => {
    if (input?.email !== null && input?.password !== null) {
      return handleLogInClick(input);
    }
  };
  return (
    <div className={styles.parent}>
      <div style={{ textAlign: "center" }}>
        <img className={styles.logo} src={LOGO} />
      </div>

      <div className={styles.container}>
        <div className={styles.heading}>Welcome back</div>
        <div className={styles.login}>Login</div>

        <FormControl error>
          <TextFieldRenderer
            name="email"
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Email Address"
          />
          {error?.isValid && error.errorCode === "TCMS-001" && (
            <div className={styles.forgotPassword}>
              {"Email  doesn't Exist"}
            </div>
          )}
          <TextFieldRenderer
            name="password"
            onChange={handleChange}
            sx={{ paddingTop: "1rem" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HttpsIcon />
                </InputAdornment>
              ),
            }}
            type="password"
            placeholder="Password"
          />

          {error?.isValid && error.errorCode === "TCMS-002" && (
            <div className={styles.forgotPassword}>{"Incorrect Password!"}</div>
          )}
          <Button
            disabled={!isDisabled()}
            onClick={onSubmit}
            sx={{ height: "4rem" }}
            className={styles.loginButton}
          >
            Login
          </Button>
        </FormControl>
      </div>
      <div className={styles.love}>
        Made with <FavoriteIcon fontSize="large" /> in India
      </div>
    </div>
  );
};

export default LoginForm;
