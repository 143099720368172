import Http from "../../../service/http";
export const getParsedData = (zones) => {
  return zones?.map((zone, i) => ({
    ...zone,
    no: i + 1,
    packageType: zone?.name,
    description: zone?.description,
  }));
};

export const getAllZoneType = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    const workspaceId = localStorage.getItem("tenantId");
    const data = await Http.get(`u/deliveryZone/workspace`, "", {
      token,
      workspaceId,
    });

    return getParsedData(data?.data);
  } catch (err) {
    console.log(err);
  }
};

export const getInputValues = (values) => {
  if (!values) return;
  const { address, email, name, phoneNumber, zoneType } = values || {};
  const [addressLine1, addressLine2, city, state, country] =
    address?.split(",");
  const [firstName, lastName] = name?.split(" ");
  return {
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    firstName,
    lastName,
    phoneNumber,
    email,
  };
};
