import React, { useState } from "react";
import { FormControl } from "@mui/material";
import TextFieldRenderer from "../textFieldRenderer/TextFieldRenderer";
import styles from "./mealType.module.scss";
import Button from "../button/Button";

const MealTypeForm = (props) => {
  const { handleOnSubmit, handleOnCancel, mealTypes } = props;
  const { basePrice, description, packageType } = mealTypes || {};

  const [input, setInput] = useState({
    name: packageType,
    unitPrice: basePrice,
    description,
  });

  const handleOnChange = (event) => {
    const { name, value } = event?.target || {};
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <div className={styles.container}>
      <FormControl sx={{ width: "100%" }}>
        <div className={styles.parent}>
          <TextFieldRenderer
            defaultValue={packageType}
            onChange={handleOnChange}
            placeholder="Package Type"
            name="name"
          />
          <TextFieldRenderer
            defaultValue={basePrice}
            onChange={handleOnChange}
            type="number"
            placeholder="Base Price"
            name="unitPrice"
          />
          <TextFieldRenderer
            defaultValue={description}
            onChange={handleOnChange}
            placeholder="Description"
            name="description"
          />
        </div>
      </FormControl>
      <div className={styles.footer}>
        <Button onClick={handleOnCancel} color="tertairy">
          Cancel
        </Button>
        <Button onClick={() => handleOnSubmit(input)}>Save</Button>
      </div>
    </div>
  );
};

export default MealTypeForm;
