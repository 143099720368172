import createHTTP from "./createHttp";
import.meta.env;
const Base_URL = "https://stageapp.mealios.com/api";

export const setDefaultModuleService = (headers) => {
  const envUrl = process.env.REACT_APP_MEALIOS_API_URL;
  const apiUrl = envUrl ? envUrl : Base_URL;
  console.log(process.env, "env variables");
  const token = localStorage.getItem("accessToken");
  const workspaceId = localStorage.getItem("tenantId");
  new Http(apiUrl, {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    Accept: "application/json",
    token,
    workspaceId,
    ...headers,
  });
};

let instance = "";
class Http {
  constructor(url, headers) {
    instance = createHTTP(url, headers);
  }

  static get(url, data, headers) {
    return instance({
      method: "GET",
      headers,
      url,
      params: data,
    });
  }

  static post(url, data, query, headers) {
    return instance({
      method: "POST",
      headers,
      url,
      data,
      params: query,
    });
  }
  static put(url, data, query, headers) {
    return instance({
      method: "PUT",
      headers,
      url,
      data,
      params: query,
    });
  }
}

export default Http;
