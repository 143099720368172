import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";

import styles from "./tableManager.module.scss";

export default function TableManager(props) {
  const {
    data,
    columns,
    headerName,
    rightSection,
    showAction,
    handleActionClick,
    isLoading,
    handlePageChange,
    totalPage,
    resultSize,
    currentPage,
    showPagination = true,
    handleRowClick = () => null,
    headerClass,
  } = props;

  const renderLoader = () => {
    return (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <TableContainer sx={{ background: "#fbf6f4" }} component={Paper}>
        <div className={`${styles.header} ${headerClass}`}>
          <div className={styles.headerClassname}>{headerName}</div>
          {rightSection && rightSection()}
        </div>
        {isLoading ? (
          renderLoader()
        ) : (
          <Table
            sx={{ minWidth: 700, borderCollapse: "separate", fontSize: "2rem" }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                {columns?.map((column) => (
                  <TableCell>{column?.headerName}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                borderSpacing: "0px 64px",
                background: "white",
                borderCollapse: "separate",
              }}
            >
              {data?.map((rows) => (
                <TableRow
                  hover
                  onClick={() => handleRowClick(rows)}
                  sx={{ cursor: "pointer" }}
                >
                  {columns?.map((value) => (
                    <TableCell
                      sx={{
                        background: "white",
                        padding: "1.7rem",
                        maxWidth: "2rem",
                      }}
                      component="th"
                      scope="row"
                    >
                      {rows[value?.id]}
                    </TableCell>
                  ))}
                  {showAction && (
                    <TableCell
                      sx={{ background: "white", padding: "1.7rem" }}
                      component="th"
                      scope="row"
                    >
                      <MoreVertIcon
                        onClick={(event) => handleActionClick(event, rows)}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {showPagination && (
        <div className={styles.pagination}>
          <Pagination
            size="large"
            page={currentPage}
            onChange={handlePageChange}
            count={totalPage}
          />
        </div>
      )}
    </div>
  );
}
