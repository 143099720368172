import Http from "../../service/http";

export const getUserDetails = async () => {
  try {
    const reponse = await Http.get("/u/user/details");
    return reponse?.data;
  } catch (err) {
    console.log(err);
  }
};

export const changeUserPassword = async (payload) => {
  try {
    const reponse = await Http.put("/p/user/reset-password", payload);
    return reponse?.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateUserDetails = async (payload) => {
  try {
    const reponse = await Http.put("/u/user/updateDetails", payload);
    return reponse?.data;
  } catch (err) {
    console.log(err);
  }
};
