import React, { useState } from "react";
import { FormControl } from "@mui/material";
import TextFieldRenderer from "../textFieldRenderer/TextFieldRenderer";
import styles from "./resumeSubscription.module.scss";
import Button from "../button/Button";
import {
  FormControlLabel,
  FormGroup,
  Checkbox,
  Radio,
  RadioGroup,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

const ResumeSubscription = (props) => {
  const { handleOnSubmit, handleOnCancel } = props;

  const [input, setInput] = useState({
    startingMeal: null,
    startingDate: null,
  });

  const handleChange = (event) => {
    const { id, value } = event?.target || {};
    setInput((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleDateChange = (date) => {
    setInput((prev) => ({
      ...prev,
      startingDate: date,
    }));
  };

  return (
    <div className={styles.container}>
      <FormControl sx={{ width: "100%" }}>
        <div className={styles.parent}>
          <div>
            <div className={styles.heading}>Meal type</div>
            <RadioGroup row>
              <FormControlLabel
                value="LUNCH"
                control={<Radio id="startingMeal" onChange={handleChange} />}
                label="Lunch"
              />
              <FormControlLabel
                value="DINNER"
                control={<Radio id="startingMeal" onChange={handleChange} />}
                label="DINNER"
              />
            </RadioGroup>
          </div>
          <div>
            <div className={styles.heading}> Starting Date</div>
            <div style={{ paddingTop: "1rem" }}>
              <DatePicker
                defaultValue={new Date()}
                inputFormat="MM/dd/yyyy"
                onChange={handleDateChange}
                name="startDate"
              />
            </div>
          </div>
        </div>
      </FormControl>
      <div className={styles.footer}>
        <Button onClick={handleOnCancel} color="tertairy">
          Cancel
        </Button>
        <Button
          disabled={input.startingMeal === null}
          onClick={() => handleOnSubmit(input)}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default ResumeSubscription;
