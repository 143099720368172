import React, { useEffect, useState } from "react";
import { FormControl, InputAdornment, FormControlLabel } from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import TextFieldRenderer from "../textFieldRenderer/TextFieldRenderer";
import styles from "./customerForm.module.scss";
import Button from "../button/Button";
import SingleSelect from "../SingleSelect/SingleSelect";
import { getAllZoneType, getInputValues } from "./customform.helper";

const CustomerForm = (props) => {
  const { handleAddCustomer, handleModalClose } = props;
  const [input, setInput] = useState({});
  const [zoneType, setZoneTypes] = useState([]);

  const init = async () => {
    const response = await getAllZoneType();
    setZoneTypes(response);
  };

  useEffect(() => {
    init();
  }, []);

  const handleZoneSelect = (value) => {
    setInput((prev) => ({
      ...prev,
      deliveryZoneId: value,
    }));
  };

  const handleFieldChange = (event) => {
    const { value, name } = event?.target || {};
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOnFormSubmit = () => {
    handleAddCustomer(input);
  };

  return (
    <div className={styles.parent}>
      <div className={styles.container}>
        <FormControl sx={{ width: "100%" }}>
          <div className={styles.firstPart}>
            <TextFieldRenderer
              onChange={handleFieldChange}
              name="firstName"
              placeholder="Enter your FirstName"
            />
            <TextFieldRenderer
              onChange={handleFieldChange}
              name="lastName"
              placeholder="Enter your LastName"
            />
          </div>
          <div className={styles.firstPart}>
            <TextFieldRenderer
              onChange={handleFieldChange}
              name="email"
              placeholder="Email"
            />
            <TextFieldRenderer
              onChange={handleFieldChange}
              name="phoneNumber"
              r
              placeholder="Phone Number"
            />
          </div>
          <div className={styles.address}>
            <TextFieldRenderer
              onChange={handleFieldChange}
              name="addressLine1"
              placeholder="Address Line 1"
            />
            <TextFieldRenderer
              onChange={handleFieldChange}
              name="addressLine2"
              placeholder="Address Line 2"
            />
          </div>
          <div className={styles.thirdPart}>
            <TextFieldRenderer
              onChange={handleFieldChange}
              name="city"
              placeholder="City"
            />
            <TextFieldRenderer
              onChange={handleFieldChange}
              name="state"
              placeholder="State"
            />
            <TextFieldRenderer
              onChange={handleFieldChange}
              name="country"
              placeholder="Country"
            />
          </div>
          <div className={styles.thirdPart}>
            <TextFieldRenderer
              onChange={handleFieldChange}
              name="zipcode"
              placeholder="Zip Code"
            />
            <div>
              <div style={{ minWidth: "17rem", marginTop: "0.4rem" }}>
                <SingleSelect
                  label="Zone Type"
                  handleChange={handleZoneSelect}
                  options={zoneType?.map((zone) => ({
                    label: zone?.packageType,
                    value: zone?.zoneId,
                  }))}
                />
              </div>
            </div>
          </div>
        </FormControl>
      </div>
      <div className={styles.footer}>
        <Button onClick={handleModalClose} color="tertairy">
          Cancel
        </Button>
        <Button onClick={handleOnFormSubmit}>Add Customer</Button>
      </div>
    </div>
  );
};

export default CustomerForm;
