export const PREVIEW_COLUMN = [
  {
    headerName: "Date",
    id: "date",
  },
  {
    headerName: "Weekday",
    id: "weekday",
  },

  {
    headerName: "Lunch",
    id: "lunch",
  },

  {
    headerName: "Dinner",
    id: "dinner",
  },
];
